// MarketInfo.tsx
import React from "react";
import { useState, useEffect } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import styled from "styled-components";
import {
  MarketData,
  SentimentData,
  RecommendationsData,
  SectorsData,
  SectorDetails,
} from "./StockDataDisplay";

// Styled components for better styling
const Container = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const ChartContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  text-align: center;
  font-size: 14px;
`;

// Helper function to format sentiment data for PieChart
const formatSentimentData = (data: SentimentData) => {
  return [
    { name: "Positive", value: data.positive },
    { name: "Neutral", value: data.neutral },
    { name: "Negative", value: data.negative },
  ];
};

// Helper function to format recommendations data for PieChart
const formatRecommendationsData = (data: RecommendationsData) => {
  return [
    { name: "Mature", value: data.overbought },
    { name: "Undervalued", value: data.oversold },
    { name: "Neutral", value: data.neutral },
  ];
};

// Helper function to format sectors data for BarChart
const formatSectorsData = (sectors: { [key: string]: SectorDetails }) => {
  return Object.entries(sectors).map(([sector, data]) => ({
    name: sector.charAt(0).toUpperCase() + sector.slice(1),
    Positive: data.positive,
    Neutral: data.neutral,
    Negative: data.negative,
  }));
};

// Updated MarketInfo component
const MarketInfo: React.FC<{ data: MarketData }> = ({ data }) => {

  const [isVertical, setIsVertical] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsVertical(window.innerWidth < 600);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const sentiment = data.sentiment || { positive: 0, neutral: 0, negative: 0 };
  const recommendations = data.recommendations || {
    overbought: 0,
    oversold: 0,
    neutral: 0,
  };
  const sectors = data.sectors || {};

  const sentimentData = formatSentimentData(sentiment);
  const recommendationsData = formatRecommendationsData(recommendations);
  const sectorsData = formatSectorsData(sectors);

  return (
    <Container>
      <div className="market-chart-container">
        <div>
          <SectionTitle>Market Sentiment</SectionTitle>
          <ChartContainer>
            <PieChart width={300} height={150}>
              <Pie
                data={sentimentData}
                dataKey="value"
                nameKey="name"
                outerRadius={50}
                fill="#8884d8"
              >
                {sentimentData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      index === 0
                        ? "var(--color-positive)"
                        : index === 1
                        ? "var(--color-neutral)"
                        : "var(--color-negative)"
                    }
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ChartContainer>
        </div>

        <div>
          <SectionTitle>Price Levels</SectionTitle>
          <ChartContainer>
            <PieChart width={300} height={150}>
              <Pie
                data={recommendationsData}
                dataKey="value"
                nameKey="name"
                outerRadius={50}
                fill="#82ca9d"
              >
                {recommendationsData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      index === 0
                        ? "var(--color-mature)"
                        : index === 1
                        ? "var(--color-negative)"
                        : "var(--color-neutral)"
                    }
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ChartContainer>
        </div>

        <div>
      <SectionTitle>Sector Breakdown</SectionTitle>
      <ChartContainer>
        <BarChart
          width={isVertical ? 300 : 1100}
          height={isVertical ? 500 : 200}
          layout={isVertical ? 'vertical' : 'horizontal'}
          data={sectorsData}
          margin={{ top: 20, right: 20, left: 30, bottom: 5 }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          {isVertical ? (
            <>
              <YAxis
                type="category"
                dataKey="name"
                width={120}
                tick={{ textAnchor: 'start' }}
                tickFormatter={(name) => name}
                tickLine={false}
                dx={-115}
              />
              <XAxis type="number" />
            </>
          ) : (
            <>
              <XAxis dataKey="name" />
              <YAxis />
            </>
          )}
          <Tooltip />
          <Legend />
          <Bar dataKey="Positive" fill="var(--color-positive)" />
          <Bar dataKey="Neutral" fill="var(--color-neutral)" />
          <Bar dataKey="Negative" fill="var(--color-negative)" />
        </BarChart>
      </ChartContainer>
    </div>
      </div>
    </Container>
  );
};

export default MarketInfo;
