import React, { useState, useEffect } from 'react';

export interface Candle {
  date: string;
  open: number;
  high: number;
  low: number;
  close: number;
  yposition?: number;
  height?: number;
}

interface CandleTileProps {
  data: Candle[];
}

const StockChartTile: React.FC<CandleTileProps> = ({ data }) => {
  const tileWidth = 240;
  const [candles, setCandles] = useState<Candle[]>([]);
  const [chartSize] = useState({width: tileWidth, height: 80});
  
  const calculateLowestHighest = (candles: Candle[]) => {

    const {lowestLow, highestHigh} = candles.reduce((acc, candle) => {
      if (candle.low < acc.lowestLow) {
        acc.lowestLow = candle.low;
      }
      if (candle.high > acc.highestHigh) {
        acc.highestHigh = candle.high;
      }
      return acc;
    }, { lowestLow: Infinity, highestHigh: -Infinity });

    const range = highestHigh - lowestLow;

    candles.forEach((candle)=> {
      const stochastic = (candle.close - lowestLow) / (highestHigh - lowestLow);
      candle.yposition = chartSize.height * (1 - stochastic);
      candle.height = (Math.abs(candle.high - candle.low) * chartSize.height) / range;
    })

    setCandles(candles); 
    
  }


  useEffect(() => {
    calculateLowestHighest(data.slice(-252));
  }, [data]);

  return (
    <div style={{ width: `240px`, height: '80px', border: '1px solid black',backgroundColor: 'black', marginBottom: '5px', borderRadius: '5px'}}>
      <svg width={chartSize.width} height={chartSize.height}>
        {candles.map((candle, index) => (
          <rect
            key={index}
            x={(index / candles.length) * chartSize.width}
            y={candle.yposition}
            width={1}
            height={candle.height}
            fill={candle.open > candle.close ? 'red' : 'green'}
          />
        ))}
      </svg>
    </div>
  );
};

export default StockChartTile;
